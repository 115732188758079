import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @Output()
  arrowClick = new EventEmitter();

  ngOnInit(): void {
  }

  arrowClicked(): void {
    this.arrowClick.emit();
  }
}
