
<div class="page">
  <!-- <h2>
    <span class="material-icons heading">
      email
    </span>
    Old School?
  </h2> -->
  <mat-card class="contactForm">
    <mat-card-header>Contact me</mat-card-header>
    <mat-card-subtitle></mat-card-subtitle>
    <mat-card-content>
      Want to work together? Talk about a project? Hit me up!
      <form class="mailform" action="https://formspree.io/f/mvovyrdd" method="POST" target="_blank">
        <mat-form-field class="full-width">
          <mat-label>Your email</mat-label>
          <input matInput placeholder="your@email.com" name="_replyto"> <!-- <input matInput type="text"> -->
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Your message</mat-label>
          <textarea matInput placeholder="Let's connect!" name="message"></textarea>
        </mat-form-field>
        <button mat-raised-button type="submit">Send</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>