<div class="landing">
  <div class="pane">
    <!-- <app-header></app-header> -->
    <div class="text">
      <p class="heading">mbrem.io</p>
      <p class="paragraph">Micha Bremicker | Web Development</p>
      <div class="bottom">
        <mat-icon class="arrow bounce" (click)="arrowClicked()">keyboard_arrow_down</mat-icon>
      </div>
    </div>
  </div>
</div>
