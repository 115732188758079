<div class="page" #page>
  <app-home (arrowClick)="arrowClicked()"></app-home>
</div>
<div class="page2">
  <app-data></app-data>
</div>

<div class="break"></div>

<app-music></app-music>

<div class="break"></div>

<app-socials></app-socials>

<app-contact></app-contact>
