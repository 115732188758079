<div class="menu">
  <img src="../../assets/menu.png" />
  <!-- <div class="expanded">
    <a href="#">home</a>
    <a href="#">interrests</a>
    <a href="#">skills</a>
    <a href="#">music</a>
    <a href="#">video</a>
  </div> -->
</div>
