import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  @ViewChild('page', {static: false})
  page: ElementRef;
  scrollY = 0;
  scrollX = 0;
  scrollBlock = false;
  currentPage = 1;

  ngOnInit(): void {
  }

  arrowClicked(): void {
    console.log(this.page.nativeElement.scrollHeight)
    this.scrollBlock = true;
    let element: number;

    element = this.page.nativeElement.scrollHeight;
    this.scrollY = element;
    window.scroll({
      top: element,
      left: 0,
      behavior: 'smooth'
    });
    this.waitForScrollAnimation();
  }

  private waitForScrollAnimation(): void {
      setTimeout(() => this.scrollBlock = false, 800);
  }
}
