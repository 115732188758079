import { Component, OnInit } from '@angular/core';
import { ChartType } from 'angular-google-charts';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {

  pieChart = ChartType.PieChart;
  pieOptions = {
    is3D: true,
    title: 'Programming Languages',
  };
  pieData = [
    ["Java/SpringBoot", 30],
    ["TypeScript/Angular", 20],
    ["JavaScript/NodeJs", 20],
    ["Scala/Spark", 20],
    ["Shell", 10],
  ];

  barChart = ChartType.BarChart;
  barOptions = {
    title: 'Experience with databases measured in storypoints',
  };
  barData = [
    ["Postgres", 1500],
    ["ElasticSearch", 1000],
    ["mongoDb", 800],
    ["OracleDb", 800],
    ["Presto", 100],
  ]

  comboChart = ChartType.ComboChart;
  comboOptions = {
    title: 'Other stuff that i did the last years',
    vAxis: {title: 'Percent of containment'},
    hAxis: {title: 'Month'},
    seriesType: 'bars',
  }
  comboColumns = ['Month', 'Microservices', 'Apache Kafka', 'Docker', 'AWS', 'Scrum', 'Bamboo', 'Jenkins', 'Git'];
  comboData = [
    ['2019/06', 100, 10, 15, 0, 60, 80, 0, 100],
    ['2019/12', 100, 80, 30, 0, 70, 90, 0, 100],
    ['2020/08', 100, 0, 20, 70, 90, 0, 60, 100],
    ['2020/12', 100, 0, 30, 90, 90, 0, 80, 100],
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
