<div class="container">
  <h2><span class="material-icons heading">
      queue_music
    </span>
    Listen to some of my music
  </h2>
  <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/971117230&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/molyphonic" title="micha" target="_blank" style="color: #cccccc; text-decoration: none;">micha</a> · <a href="https://soundcloud.com/molyphonic/strawberry" title="Strawberry" target="_blank" style="color: #cccccc; text-decoration: none;">Strawberry</a></div>
  <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/920659516&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/molyphonic" title="micha" target="_blank" style="color: #cccccc; text-decoration: none;">micha</a> · <a href="https://soundcloud.com/molyphonic/dreams" title="Dreams" target="_blank" style="color: #cccccc; text-decoration: none;">Dreams</a></div>
  <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/482940372&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/molyphonic" title="micha" target="_blank" style="color: #cccccc; text-decoration: none;">micha</a> · <a href="https://soundcloud.com/molyphonic/blue-sky" title="Blue Sky" target="_blank" style="color: #cccccc; text-decoration: none;">Blue Sky</a></div>
  <div class="video-container"><iframe src="https://www.youtube-nocookie.com/embed/d0i3AARHCeE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
</div>
