<div class="container">
  <h1><span class="material-icons">insights</span> Insights</h1>

  <h2 class="wholeLine"><span class="material-icons heading">self_improvement</span> Personality 
    <span class="subheading">tested with <a href="https://birkman.com/the-birkman-method/" target="_blank">The Birkman Method</a></span>
  </h2>

  <div class="dashboard">

    <app-card class="card" [percent]="94" [title]="'Music'" [color]="'warn'"
      [description]="'My biggest passion is music. Producing, singing and jamming is what makes me happy.'">
    </app-card>
    <app-card class="card" [percent]="82" [title]="'Art'" [color]="'warn'"
      [description]="'Art reflects my interest and passion for design. If i find something aesthetically pleasing, it makes me happy as well.'">
    </app-card>
    <app-card class="card" [percent]="78" [title]="'Literature'" [color]="'accent'"
      [description]="'The appreciation of speech. I enjoy poetry just as much as well written code.'">
    </app-card>
    <app-card class="card" [percent]="75" [title]="'Management'" [color]="'accent'"
      [description]="'Managing systems, documenting flows and categorizing are things that I would consider as calming.'">
    </app-card>

    <app-card class="card" [percent]="72" [title]="'Tech'" [color]="'accent'"
      [description]="'Programming. I have fun doing it.'">
    </app-card>
    <app-card class="card" [percent]="64" [title]="'Science'" [color]="'primary'"
      [description]="'Elon Musk fanboy here. Ask me anything about space, and you\'ll regret it.'">
    </app-card>
    <app-card class="card" [percent]="50" [title]="'Social Affairs'" [color]="'primary'"
      [description]="'I\'m happy it made it on the list! I enjoy mentoring (receiving and giving), friendships and coffee breaks.'">
    </app-card>
    <app-card class="card" [percent]="38" [title]="'Convincing'" [color]="'primary'"
      [description]="'Convincing, motivating, selling.'">
    </app-card>

    <h2 class="wholeLine"><span class="material-icons heading">biotech</span> Tech experience <span
        class="subheading">tested with creativity</span></h2>

    <div class="chart">
      <google-chart style="width: 100%; height: 100%" [dynamicResize]="true" [type]="pieChart" [data]="pieData" [options]="pieOptions">
      </google-chart>
    </div>
    <div class="chart">
      <google-chart style="width: 100%; height: 100%" [dynamicResize]="true" [type]="barChart" [data]="barData" [options]="barOptions">
      </google-chart>
    </div>
    <div class="chart">
      <google-chart style="width: 100%; height: 100%" [dynamicResize]="true" [type]="comboChart" [data]="comboData" [columns]="comboColumns" [options]="comboOptions">
      </google-chart>
    </div>
  </div>
</div>
