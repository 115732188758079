<div class="icons">
  <h2><span class="material-icons heading">
    usb
    </span>
  Let's connect!
  </h2>
  <a href="https://www.instagram.com/micha.jpg/" target="_blank">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" width="106px" height="106px" viewBox="0 0 500 500" fill="none" xml:space="preserve">
      <g>
        <path fill-rule="evenodd" d="M246.341,19.102c-130.471,0-236.238,105.767-236.238,236.238S115.87,491.578,246.341,491.578
                  S482.579,385.811,482.579,255.34S376.811,19.102,246.341,19.102z M378.971,341.318c-0.015,9.604-2.219,18.675-7.689,26.737
                  c-6.997,10.311-16.852,16.003-29.218,16.043c-58.106,0.187-116.215,0.267-174.32-0.022c-21.623-0.108-47.805-18.735-46.882-47.609
                  C121.99,301.169,121,265.806,121,230.471c0-2.151,0-4.471,0-7.471c24,0,46.349,0,69.287,0c-14.667,40-7.961,72.604,28.612,95.889
                  c25.002,15.918,57.68,12.577,81.012-6.003C325.961,292.141,332.042,265,319.372,223c19.613,0,38.628,0,59.628,0
                  c0,2,0,3.982,0,5.834C379,266.329,379.03,303.823,378.971,341.318z M197.401,257.087c-0.097-31.873,25.679-57.701,57.568-57.685
                  c31.948,0.016,57.317,25.591,57.293,57.756c-0.024,31.979-25.843,57.722-57.68,57.513
                  C223.068,314.464,197.497,288.712,197.401,257.087z M378.993,206.354c-2.548,0.137-4.631,0.344-6.714,0.347
                  c-20.219,0.023-40.44,0.143-60.655-0.132c-2.995-0.041-6.603-1.249-8.848-3.176c-28.621-24.563-67.485-24.451-96.158,0.417
                  c-1.631,1.414-4.015,2.761-6.058,2.776c-25.322,0.18-50.645,0.112-75.968,0.081c-0.93-0.001-1.859-0.269-2.999-0.446
                  c0-14.35-1.334-28.585,0.319-42.464c2.365-19.866,24.171-37.198,44.948-37.248c58.498-0.143,116.996-0.103,175.494-0.019
                  c17.475,0.025,32.283,12.793,35.307,31.878c1.734,10.942,1.015,22.279,1.312,33.441
                  C379.097,196.479,378.993,201.157,378.993,206.354z" />
        <path fill-rule="evenodd" d="M338.86,142.471c-10.793-0.059-21.588-0.109-32.38,0.01c-5.204,0.057-7.886,2.88-7.848,8.185
                  c0.038,5.299,0.008,10.598,0.006,15.896c0,5.299,0.083,10.599-0.023,15.896c-0.107,5.32,2.599,8.336,7.669,8.467
                  c10.786,0.279,21.586,0.272,32.374,0.052c5.282-0.108,8.324-3.128,8.339-8.634c0.029-10.597,0.108-21.196-0.04-31.791
                  C346.886,145.449,344.134,142.5,338.86,142.471z" />
      </g>
    </svg>
  </a>
  <a href="https://github.com/michabremicker" target="_blank">
    <svg width="100" height="100" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg"
      class="icon-git">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z"
        transform="scale(64)"></path>
    </svg>
  </a>
  <a href="https://www.xing.com/profile/Micha_Bremicker" target="_blank">
    <svg width="103" height="103" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" xml:space="preserve"
      class="icon-xing">
      <g>
        <path
          d="M500,10C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490c270.6,0,490-219.4,490-490C990,229.4,770.6,10,500,10z M340.6,622.9h-88.6c-5.3,0-9.3-2.4-11.6-6c-2.3-3.8-2.5-8.7,0-13.6l94.1-165.6c0.1-0.2,0.1-0.3,0-0.5l-59.9-103.4c-2.5-4.9-2.8-9.8-0.5-13.6c2.3-3.7,6.7-5.5,12.1-5.5h88.6c13.6,0,20.2,8.7,24.7,16.6c0,0,60.5,105.3,60.9,105.9c-3.6,6.3-95.6,168.7-95.6,168.7C360.3,614,353.9,622.9,340.6,622.9L340.6,622.9z M759.4,213L563.3,558.8c-0.1,0.2-0.1,0.4,0,0.6L688.2,787c2.5,4.9,2.5,9.9,0.2,13.7c-2.3,3.6-6.4,5.6-11.8,5.6h-88.5c-13.6,0-20.4-9-24.7-16.8c0,0-125.5-229.7-125.8-230.3c6.3-11.1,197.1-348.6,197.1-348.6c4.7-8.5,10.5-16.8,23.7-16.8h89.5c5.3,0,9.5,2,11.8,5.6C762,203.2,761.9,208.1,759.4,213L759.4,213z" />
      </g>
    </svg>
  </a>
  <a href="https://www.linkedin.com/in/micha-bremicker-b689a61b5/" target="_blank">
    <svg width="110" height="110" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 100">
      <path
        d="M55.35,44.17h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11ZM50.8,3.77A45.67,45.67,0,1,0,96.47,49.44,45.72,45.72,0,0,0,50.8,3.77ZM39.38,70a.77.77,0,0,1-.77.76h-8.8a.76.76,0,0,1-.76-.76V40.43a.76.76,0,0,1,.76-.77h8.8a.77.77,0,0,1,.77.77ZM33.9,35.71a5.53,5.53,0,1,1,5.53-5.53A5.52,5.52,0,0,1,33.9,35.71ZM76.62,70a.77.77,0,0,1-.77.76h-8.8a.76.76,0,0,1-.76-.76V54.11c0-4.18-1.49-7-5.23-7a5.65,5.65,0,0,0-5.3,3.78,7.12,7.12,0,0,0-.34,2.52V70a.77.77,0,0,1-.77.77h-8.8a.76.76,0,0,1-.76-.77c0-4.22.11-24.71,0-29.53a.76.76,0,0,1,.76-.77h8.78a.76.76,0,0,1,.77.77v3.63a10.26,10.26,0,0,1,9.31-5.13c6.79,0,11.89,4.44,11.89,14Zm-21.2-25.8v-.11l-.07.11Zm-.07,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Zm0,0h.07v-.11Z" />
    </svg>
  </a>
</div>