<mat-card>
  <mat-card-title>
    <div class="spinner-container">
      <div class="spinner-background">{{ percent }}%</div>
      <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="percent">
      </mat-progress-spinner>
    </div>
    {{ title }}
  </mat-card-title>
  <mat-card-subtitle>{{ description }}</mat-card-subtitle>
</mat-card>
